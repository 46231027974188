/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/saga-green/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

body{
    margin: 0 !important;
}

.p-menubar{
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1200;
}


.p-toast{
    z-index: 1500 !important;
}

.avatar.p-avatar{
    display: grid;
}

.p-dialog-mask{
  z-index:1300 !important;;
}
p-confirmdialog{
    .p-dialog-mask{
        z-index:2000 !important;
      }
}

.multiselect-custom-virtual-scroll{
    >.p-multiselect{
        width: 100%;
    }
}

.btn-qualification{
    margin: 1rem;
    max-width: 90%;
    @media (max-width:48em) {
        width: 100%;
        .p-button{
            width: 100%;
        }
    }
    
}

.speeddial-linear-demo {
    .p-speeddial-direction-up {
        left: calc(50% - 2rem);
        bottom: 0;
    }

    .p-speeddial-direction-down {
        left: calc(50% - 2rem);
        top: 0;
    }

    .p-speeddial-direction-left {
        right: 0;
        top: calc(50% - 2rem);
    }

    .p-speeddial-direction-right {
        left: 0;
        top: calc(50% - 2rem);
    }
}

.p-speeddial-button.p-button.p-button-icon-only{
    width: 2.5rem;
    height: 2.5rem;
}